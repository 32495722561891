<template>
  <div class="grid grid-cols-1 md:grid-cols-3 text-center">
    <div class="md:text-left">
      Amerasian (Pvt) Ltd © {{ new Date().getFullYear() }}
    </div>

    <div class="text-center">
      <router-link to="/privacy-policy">
        Privacy Policy
      </router-link>
    </div>

    <div class="md:text-right">
      v{{ $store.state.appConfig.layout.version }}
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
